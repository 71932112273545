import React, { useContext } from 'react';
import './CartValue.css';
import { MoneyFormatterContext } from '../../../services/context/MoneyFormatterContext.js';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import { CartContext } from '../../../services/context/CartContext.js';

/**
 * @returns {JSX.Element} CartValue Component
 */
export default function CartValue() {
  const cart = useContext(CartContext);
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const totalCost = cart.map(voucher => MoneyFormatter.getValue(voucher.cost))
    .reduce((prev, curr) => MoneyFormatter.add(prev, curr), MoneyFormatter.get(0));
  const totalValue = cart.map(voucher => MoneyFormatter.getValue(voucher.options.buyNow.value))
    .reduce((prev, curr) => MoneyFormatter.add(prev, curr), MoneyFormatter.get(0));
  return (
    <section className='cart-value'>
      <section className='pay container-v'>
        <header>
          <p>You Pay</p>
        </header>
        <main>
          <p>{MoneyFormatter.format(totalCost)}</p>
        </main>
      </section>
      <section className='container-v'>
        <header>
          <p>You Get</p>
        </header>
        <main className='container-v'>
          {
            MoneyFormatter.subtract(totalCost, totalValue).amount !== 0
              && <p className='extra-info'>
                {MoneyFormatter.format(MoneyFormatter.subtract(totalCost, totalValue))}
                {' '}
                extra
              </p>
          }
          <p>{MoneyFormatter.format(totalValue)}
          </p>
        </main>
      </section>
    </section>
  );
}

CartValue.propTypes = { cart: PropTypeShapes.cart };
