import React from 'react';
import './TopBack.css';
import BackArrow from '../../assets/BackArrow.jsx';
import { useNavigate, useLocation } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';

/**
 * @returns {JSX.Element} TopBack Component
 */
export default function TopBack() {
  const navigateTo = useNavigate();
  const location = useLocation();

  return (
    <button
      className='top-back container-v'
      onClick={ () => (location.key === 'default' ? navigateTo(ROUTES.HOME) : navigateTo(-1)) }
    >
      <BackArrow />
      Back
    </button>
  );
}
