// not camel to link up to analytics spec
/* eslint-disable camelcase */

export const traceId = 'X-B3-Traceid';

export const app_name = 'voucher advance';

export const page_channel = 'mobile app';

export const page_section = 'voucher advance';

export const visitor_login_status = 'logged in';

export const page_type = 'webview';

export const pagePrefix = `vodapay: ${app_name}`;

export const mini_app_id = '<app id>';

export const events = {
  error: 'event_error',
  formError: 'form_error',
  pageView: 'page_view',
  interaction: 'ui_interaction',
  productView: 'product_view',
  addToCart: 'cart_add',
  cartView: 'cart_open',
  removeFromCart: 'cart_remove',
  buyNowSuccess: 'voucher_advance_buy_now_success',
  transactionSuccess: 'transaction_complete',
  journeyStart: 'journey_start',
  checkoutStart: 'checkout_start',
  journeyEnd: 'journey_end',
  advanceSuccess: 'voucher_advance_success',
  subscribeSuccess: 'voucher_advance_subscribe_success',
};

export const defaultAnalyticsVariables = {
  mini_app_id,
  app_name,
  page_section,
  page_channel,
  visitor_login_status,
  page_type,
};
