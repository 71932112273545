import { createContext, Context, Dispatch, SetStateAction } from 'react';

/**
 * @type {Context<object>}
 */
export const SubscriptionDetailsContext = createContext(undefined);

/**
 * @type {Context<Dispatch<SetStateAction<object>>>}
 */
export const UpdateSubscriptionDetailsContext = createContext(def => def);
