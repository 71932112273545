import React, { useContext, useEffect, useRef, useState } from 'react';
import { BearerTokenContext } from '../services/context/BearerTokenContext.js';
import useApis from '../services/hooks/useApis.js';
import { processCategoriesResponse, populateVoucherClubCategory } from '../services/ResponseProcessor.js';
import { CategoriesCompleteContext, CategoriesContext } from '../services/context/CategoriesContext.js';
import PropTypes from 'prop-types';
import { ConfigContext } from '../services/context/ConfigContext.js';
import { withSentrySpan } from '../services/Sentry.js';
import { addBreadcrumb, captureException, captureMessage, startTransaction } from '@sentry/react';
import { SentryConfig } from '../services/Constants/Sentry.js';
import { defaultAnalyticsVariables, events, pagePrefix, traceId } from '../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../services/context/AnalyticsPageContext.js';
import { NetworkError } from '../services/Constants/Errors.js';
import { EligibilityContext } from '../services/context/EligibilityContext.js';
import { Category } from '../services/Constants/ObjectDefinitions.js';
import { createIsAdvanceOptionFilter } from '../services/EligibilityOffers.js';
import { MoneyFormatterContext } from '../services/context/MoneyFormatterContext.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function CategoriesController({ slot }) {
  const [tries, setTries] = useState(0);
  const maxTries = 5;
  const categoriesRef = useRef(/** @type {Array<Category>} */null);
  const [complete, setComplete] = useState(false);
  const bearerToken = useContext(BearerTokenContext);
  const analyticsName = useContext(AnalyticsPageContext);
  const { getCategories } = useApis();
  const config = useContext(ConfigContext);
  const eligibility = useContext(EligibilityContext);
  const MoneyFormatter = useContext(MoneyFormatterContext);

  const mergeArray = (arr, baseArr) => {
    if (!baseArr) return arr;
    if (!arr) return baseArr;
    const idMap = new Map(baseArr.map(element => [
      element.id,
      element,
    ]));
    for (const element of arr) idMap.set(element.id, element);
    return [...idMap.values()];
  };

  const getCurrentById = (obj, curArr) => curArr.find(value => value.id === obj.id);

  const mergeCategories = nextCategories => {
    const categories = categoriesRef.current;
    if (!categories) return nextCategories;
    const mergedCategories = mergeArray(nextCategories, categories);
    return mergedCategories.map(category => ({
      ...category,
      vendors: mergeArray(category.vendors, getCurrentById(category, categories).vendors).map(vendor => ({
        ...vendor,
        vouchers: mergeArray(vendor.vouchers, getCurrentById(vendor, getCurrentById(category, categories).vendors).vouchers),
      })),
    }));
  };

  const processCategories = async response => {
    setComplete(response.data.catalogue.complete);
    return populateVoucherClubCategory(processCategoriesResponse(response.data.categories), config.frontend.general.subscriptionPromotionTag);
  };

  useEffect(() => {
    if (bearerToken && config && !complete && tries < maxTries) {
      const initialCatalogueCallTransaction = startTransaction(SentryConfig.admin.initialCatalogue.transaction);

      withSentrySpan(
        initialCatalogueCallTransaction,
        SentryConfig.admin.initialCatalogue.spans.getCatalogue(tries, maxTries),
        () => getCategories(bearerToken),
      )
        .then(response => withSentrySpan(
          initialCatalogueCallTransaction,
          SentryConfig.admin.initialCatalogue.spans.processCatalogue,
          () => processCategories(response).then(mergeCategories),
        ))
        .then(value => {
          categoriesRef.current = value;
        }).catch(error => {
          if (error instanceof NetworkError) {
            const { response } = error;
            window.utag?.link({
              ...defaultAnalyticsVariables,
              page_name: analyticsName,
              event_name: [events.error],
              link_id: `${pagePrefix}: error`,
              event_error_name: 'magento catalogue error',
              event_error_code: response.headers?.get(traceId),
              event_error_type: 'system error',
            });

            if (response?.headers?.get(traceId)) {
              addBreadcrumb({
                category: 'traceid',
                message: response.headers?.get(traceId),
              });
            }

            captureException(new Error(`Magento Catalogue Error: ${response.status}`));
          } else throw error;
        })
        .finally(() => setTimeout(() => {
          setTries(prevState => prevState + 1);
          initialCatalogueCallTransaction.finish();
        }, 100));
    }
  }, [
    bearerToken,
    config,
    tries,
  ]);

  useEffect(() => {
    if (complete && categoriesRef.current && eligibility?.advanceOffers) {
      const vouchers = categoriesRef.current
        .flatMap(category => category.vendors)
        .flatMap(vendor => vendor.vouchers);
      if (!vouchers.every(voucher => {
        const isAdvanceOption = createIsAdvanceOptionFilter(eligibility, voucher, MoneyFormatter);
        return voucher.options.advance.terms.options.every(option => {
          if (!isAdvanceOption(option)) {
            addBreadcrumb({
              category: 'Magento config mismatch',
              message: voucher.name,
              level: 'warning',
            });
            addBreadcrumb({
              category: 'offerId',
              message: option.offerId,
              level: 'warning',
            });
            return false;
          }

          return true;
        });
      })) {
        captureMessage('Unlinked Magento advance offers', 'warning');
      }
    }
  }, [
    complete,
    eligibility,
  ]);

  useEffect(() => {
    if (tries >= maxTries) setComplete(true);
  }, [tries]);

  return (
    <CategoriesContext.Provider value={ categoriesRef.current ?? [] }>
      <CategoriesCompleteContext.Provider value={ complete }>
        {slot}
      </CategoriesCompleteContext.Provider>
    </CategoriesContext.Provider>
  );
}

CategoriesController.propTypes = { slot: PropTypes.element };
