import React, { useContext, useEffect } from 'react';
import CategoryVendors from '../../components/Category/Vendors/CategoryVendors.jsx';
import CategoryVouchers from '../../components/Category/Vouchers/CategoryVouchers.jsx';
import { CategoriesCompleteContext, CategoriesContext } from '../../services/context/CategoriesContext.js';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import Redirect from '../../components/Redirect/Redirect.jsx';
import { useParams } from 'react-router-dom';
import TopBack from '../../components/TopBack/TopBack.jsx';
import ExtraValueTryNowBox from '../ExtraValueTryNowBox/ExtraValueTryNowBox.jsx';

/**
 * @returns {JSX.Element} CategoryPage component
 */
export default function CategoryPage() {
  const { id: idParam } = useParams();
  const categoryId = Number(idParam);
  const categories = useContext(CategoriesContext);
  const complete = useContext(CategoriesCompleteContext);
  const category = categories?.find(element => element.id === categoryId);

  const analyticsName = `${pagePrefix}: category: ${category?.name}`;
  useEffect(() => {
    if (category) {
      window.utag?.view({
        ...defaultAnalyticsVariables,
        page_name: analyticsName,
        event_name: [events.pageView],
      });
    }
  }, []);

  if (!category && complete) {
    return <Redirect to={ ROUTES.HOME } />;
  }

  return (
    <AnalyticsPageContext.Provider value={ analyticsName }>
      <TopBack />
      <ExtraValueTryNowBox />
      <CategoryVendors category={ category } />
      <CategoryVouchers category={ category } />
    </AnalyticsPageContext.Provider>
  );
}
