import { prioritySort } from './Sort.js';
import { getTerm } from './AdvanceTermCalculator.js';
import { Category, Vendor, Voucher } from './Constants/ObjectDefinitions.js';

/**
 * @param {Array<Voucher>} vouchersResponse - unprocessed array of vouchers
 * @returns {Array<Voucher>} - ordered array of complete vouchers
 */
export const processVouchersResponse = vouchersResponse => vouchersResponse
  ?.filter(voucher => voucher.complete)
  .sort((a, b) => prioritySort(a, b, true));

/**
 * @param {Vendor} vendor - unprocessed vendor
 * @returns {Vendor} - vendor with processed vouchers
 */
export const processVendorResponse = vendor => ({
  ...vendor,
  vouchers: processVouchersResponse(vendor.vouchers),
});

/**
 * @param {Array<Vendor>} vendors - unprocessed array of vendors
 * @returns {Array<Vendor>} - ordered array of processed vendors
 */
export const processVendorsResponse = vendors => vendors
  .map(vendor => processVendorResponse(vendor))
  .sort((a, b) => prioritySort(a, b, true));

/**
 * @param {Category} category - unprocessed category
 * @returns {Category} - category with processed vendors
 */
export const processCategoryResponse = category => ({
  ...category,
  vendors: processVendorsResponse(category.vendors),
});

/**
 * @param {Array<Category>} categoriesResponse - unprocessed array of categories
 * @returns {Array<Category>} - ordered array of processed categories
 */
export const processCategoriesResponse = categoriesResponse => categoriesResponse
  .map(category => processCategoryResponse(category))
  .sort((a, b) => prioritySort(a, b, true));

/**
 * @param {Array<categories>} categories - unprocessed array of VodaPay Club categories
 * @param {string} uiConfigSubscriptionPromotionTag - promotion id from Backend config
 * @returns {Array<categories>} - processed array of VodaPay Club categories
 */
export const populateVoucherClubCategory = (categories, uiConfigSubscriptionPromotionTag) => {
  const clubCategoryIndex = categories.findIndex(cat => cat.vendors.length === 0); // Assumption: Only club category has zero vendors

  if (clubCategoryIndex === -1) {
    return categories;
  }

  const clubCategory = {
    ...categories[clubCategoryIndex],
    isClubCategory: true,
  }; // clone to avoid modification in original object
  const clubVendors = categories
    .filter(cat => cat.id !== clubCategory.id)
    .reduce((vendors, { vendors: v }) => [
      ...vendors,
      ...v,
    ], [])
    .map(vendor => ({
      ...vendor,
      vouchers: vendor.vouchers.filter(voucher => voucher.promotionTag?.toString() === uiConfigSubscriptionPromotionTag.id),
    }))
    .filter(vendor => vendor.vouchers.length);

  clubCategory.vendors = clubVendors;
  categories[clubCategoryIndex] = clubCategory;

  return categories;
};

/**
 * @param {Voucher} voucher - voucher to process into request
 * @param {Date} date - selected repayment date
 * @returns {Array} - cartItems request value
 */
export const processAdvanceIntoRequest = (voucher, date) => [
  {
    voucherId: voucher.id,
    isAdvance: true,
    payment: voucher.options.advance.payment,
    terms: {
      prefix: voucher.options.advance.terms.prefix,
      option: getTerm(voucher, date),
    },
  },
];

/**
 * @param {Array<Voucher>} cart - cart to process
 * @returns {Array} - cartItems request value
 */
export const processCartIntoRequest = cart => {
  const uniqueVoucherIds = new Set(cart.map(voucher => voucher.id));

  return [...uniqueVoucherIds]
    .map(voucherId => cart.find(voucher => voucher.id === voucherId))
    .map(voucher => ({
      quantity: cart.filter(item => item.id === voucher.id).length,
      voucherId: voucher.id,
      payment: voucher.options.buyNow.payment,
    }));
};
