import React from 'react';
import NavItem from '../NavItem.jsx';
import VodaPayClubIcon from '../../../assets/VodaPayClubIcon.jsx';

/**
 * @returns {JSX.Element} NavVodaPayClub Component
 */
export default function NavVodaPayClub() {
  const routePage = localStorage.getItem('routePage');

  return (
    <NavItem
      icon={ <VodaPayClubIcon /> }
      route={ routePage }
      name='VodaPay Club'
    />
  );
}
