import React, { useContext } from 'react';
import AddToCartButtons from '../AddToCart/AddToCartButtons.jsx';
import { Voucher } from '../../services/Constants/ObjectDefinitions.js';
import PropTypeShapes from '../../services/Constants/PropTypeShapes.jsx';
import PropTypes from 'prop-types';
import StockStatus from '../../services/Constants/StockStatus.js';
import { SubscriptionDetailsContext } from '../../services/context/SubscriptionDetailsContext.js';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
/**
 * @param {object} props - the props of the component
 * @param {Voucher} props.voucher - the voucher to check
 * @param {JSX.Element} props.isClubVoucher - vodapay club voucher check
 * @returns {JSX.Element} StockChecker component
 */
export default function StockChecker({ voucher, isClubVoucher = false }) {
  const subscriptionDetails = useContext(SubscriptionDetailsContext);
  const navigateTo = useNavigate();
  if (isClubVoucher && (subscriptionDetails?.expiryDate && !subscriptionDetails?.isSubscribed)) {
    return voucher.stock.status === StockStatus.inStock
      ? (
        <button
          className='secondary-button'
          onClick={ () => navigateTo(ROUTES.JOIN_VODAPAY_CLUB) }
        >
          Join the club
        </button>) : null;
  } else if (isClubVoucher && (!subscriptionDetails?.expiryDate && !subscriptionDetails?.isSubscribed)) {
    return null;
  }

  return voucher.stock.status === StockStatus.inStock ? <AddToCartButtons voucher={ voucher } /> : <>Out of stock</>;
}

StockChecker.propTypes = {
  voucher: PropTypeShapes.voucher,
  isClubVoucher: PropTypes.bool,
};
