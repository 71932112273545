import React, { useContext, useEffect, useState } from 'react';
import './BottomNav.css';
import NavHome from '../NavItem/Home/NavHome.jsx';
import NavMyVouchers from '../NavItem/My Vouchers/NavMyVouchers.jsx';
import NavVodaPayClub from '../NavItem/VodaPay Club/NavVodaPayClub.jsx';
import NavCart from '../NavItem/Cart/NavCart.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { SubscriptionDetailsContext } from '../../services/context/SubscriptionDetailsContext.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { UpdateSnackBarContext } from '../../services/context/SnackBarContext.js';
import FailIcon from '../../assets/FailIcon.jsx';
import Loading from '../../components/Loading/Loading.jsx';
import { UserDetailsContext } from '../../services/context/UserDetailsContext.js';

/**
 * @returns {JSX.Element} BottomNav Component
 */
export default function BottomNav() {
  const analyticsName = `${pagePrefix}: landing`;
  const navigateTo = useNavigate();
  const location = useLocation();
  const subscriptionDetails = useContext(SubscriptionDetailsContext);
  const [loading, setLoading] = useState(/** @type {string} */null);
  const setSnackBar = useContext(UpdateSnackBarContext);
  const userDetails = useContext(UserDetailsContext);

  useEffect(() => {
    if (subscriptionDetails && subscriptionDetails?.expiryDate) {
      setLoading(null);
    }
  }, [subscriptionDetails]);

  const onClickVodaPayClub = details => {
    setLoading('Loading...');
    if (subscriptionDetails && subscriptionDetails?.expiryDate) {
      setLoading(null);
      const route = details.isSubscribed ? ROUTES.VODAPAY_CLUB : ROUTES.JOIN_VODAPAY_CLUB;
      localStorage.setItem('routePage', route);
      navigateTo(route);
    } else if ((subscriptionDetails && !subscriptionDetails?.expiryDate) || (subscriptionDetails?.status === 500)) {
      setLoading(null);
      setSnackBar({
        icon: <FailIcon />,
        body: 'Service unavailable now. Please try again.',
      });
    }
  };

  const subscriptionStatus = subscriptionDetails?.subscriptionStatus === 'unsubscribed' ? 'not active' : subscriptionDetails?.subscriptionStatus;

  const onClickAnalytcics = () => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      event_name: [events.interaction],
      page_name: analyticsName,
      link_id: `${pagePrefix}: menu: vodapay: club`,
      visitor_addon_type_active: `${defaultAnalyticsVariables.app_name}: subscription: ${subscriptionStatus}`,
    });
  };

  return (
    <nav className='bottom-nav'>
      <div className='v-container'>
        <button
          className='container-h'
          onClick={ () => navigateTo(ROUTES.HOME) }
        >
          <NavHome />
        </button>
      </div>
      <div className='v-container'>
        <button
          className='container-h'
          onClick={ () => navigateTo(ROUTES.MY_VOUCHERS) }
        >
          <NavMyVouchers />
        </button>
      </div>
      {
        userDetails.vsp === true
    && <div className='v-container'>
      <button
        className='container-h'
        onClick={
          () => {
            onClickVodaPayClub(subscriptionDetails);
            onClickAnalytcics();
          }
        }
      >
        <NavVodaPayClub />
      </button>
    </div>
      }
      <div className='v-container'>
        <button
          className='container-h'
          onClick={ () => (location.pathname === ROUTES.CART ? navigateTo(ROUTES.CART) : navigateTo(ROUTES.CROSS_SELL)) }
        >
          <NavCart />
        </button>
      </div>
      { loading && <Loading headerText={ loading } /> }
    </nav>
  );
}
