import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PromosContext } from '../services/context/PromosContext.js';
import useApis from '../services/hooks/useApis.js';
import { BearerTokenContext } from '../services/context/BearerTokenContext.js';
import { ConfigContext } from '../services/context/ConfigContext.js';
import { CategoriesCompleteContext, CategoriesContext } from '../services/context/CategoriesContext.js';
import { getVoucherVendor } from '../services/ReverseHierarchySearch.js';
import Slider from '../components/Slider/Slider.jsx';
import ProductVoucher from '../components/ProductVoucher/ProductVoucher.jsx';
import AddToCartButtons from '../components/AddToCart/AddToCartButtons.jsx';
import useVoucherProcessor from '../services/hooks/useVoucherProcessor.js';
import { SubscriptionDetailsContext } from '../services/context/SubscriptionDetailsContext.js';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../services/Constants/GlobalRoutes.jsx';
import { UserDetailsContext } from '../services/context/UserDetailsContext.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The PromosController Component
 */
export default function PromosController({ slot }) {
  const [carouselItems, setCarouselItems] = useState([]);
  const [currentPromo, setCurrentPromo] = useState(null);
  const [promos, setPromos] = useState(/** @type {Array<{promotionTagLabel: string, id: string, productRanking: number}>} */[]);
  const [numPromos, setNumPromos] = useState(1); // Assume at least one promo at all time
  const pageSize = 5;
  const bearerToken = useContext(BearerTokenContext);
  const config = useContext(ConfigContext);
  const categories = useContext(CategoriesContext);
  const categoriesComplete = useContext(CategoriesCompleteContext);
  const { getBuyNowPercentValue } = useVoucherProcessor();
  const subscriptionDetails = useContext(SubscriptionDetailsContext);

  const { getPromos } = useApis();
  const navigateTo = useNavigate();
  const isTrialAvailable = subscriptionDetails?.isTrialAvailable;
  const isSubscribed = subscriptionDetails?.isSubscribed;
  const showButtonText = 'Subscribe';
  const userDetails = useContext(UserDetailsContext);

  useEffect(() => {
    if (!(config && bearerToken)) return;
    if (promos.length < numPromos) {
      const currentPage = Math.ceil((promos.length + 1) / pageSize); // pages start at 1
      getPromos(bearerToken, pageSize, currentPage)
        .then(data => {
          setNumPromos(data.totalCount);
          const jointPromos = [
            ...promos,
            ...data.items,
          ];
          const promoIds = new Set(jointPromos.map(promo => promo.id));
          setPromos([...promoIds].map(id => jointPromos.find(promo => promo.id === id)));
        });
    }
  }, [
    config,
    bearerToken,
    numPromos,
    promos,
  ]);

  useEffect(() => {
    if (categories && promos && categoriesComplete) {
      const orderedPromos = promos.toSorted((a, b) => Number(a.productRanking) - Number(b.productRanking));
      const vendors = categories.flatMap(category => category.vendors);
      const vouchers = vendors.flatMap(vendor => vendor.vouchers ?? []);
      const promoVouchers = orderedPromos.map(promo => ({
        promo,
        voucher: vouchers.find(voucher => voucher.id === promo.id),
      })).filter(({ voucher }) => voucher);

      const comingSoon = categories.flatMap(category => category.vendors).filter(vendor => vendor.vouchers.length === 0)
        .map(vendor => ({
          backgroundUrl: vendor.backgroundUrl,
          iconUrl: vendor.iconUrl,
          altText: vendor.name,
          headerText: 'Coming soon.',
        }));

      const promoItems = promoVouchers.map(({ promo, voucher }) => {
        const extraValue = Math.floor(getBuyNowPercentValue(voucher));
        const vendor = getVoucherVendor(categories, voucher);
        return {
          backgroundUrl: voucher.productUrl,
          iconUrl: vendor.iconUrl,
          altText: vendor.name,
          headerText: extraValue ? `${Math.floor(extraValue)}% More` : promo.promotionTagLabel,
          subText: extraValue ? promo.promotionTagLabel : undefined,
          actionItem: (
            <button
              className='secondary-button'
              onClick={ () => setCurrentPromo(voucher) }
            >
              View Voucher
            </button>),
        };
      });

      const subscriptionCarousel = (userDetails.vsp === true && !isSubscribed && !isTrialAvailable && subscriptionDetails?.expiryDate)
        ? [
          {
            backgroundUrl: config.frontend.general.subscriptionCarouselBannerUrl,
            headerText: 'UP TO 50% EXTRA VALUE',
            subText: 'Exclusive deals & vouchers from top brands!',
            actionItem: (
              <button
                className='secondary-button'
                onClick={
                  () => {
                    navigateTo(ROUTES.JOIN_VODAPAY_CLUB);
                  }
                }
              >
                { showButtonText }
              </button>),
          },
        ] : [];

      setCarouselItems([
        ...promoItems,
        ...comingSoon,
        ...subscriptionCarousel,
      ]);
    }
  }, [
    categories,
    promos,
    categoriesComplete,
    subscriptionDetails,
  ]);

  return (
    <PromosContext.Provider value={ carouselItems }>
      { slot }
      {
        currentPromo
          ? (
            <Slider
              onClickOutside={ () => setCurrentPromo(null) }
              slot={
                <ProductVoucher
                  voucher={ currentPromo }
                  actionItem={
                    <AddToCartButtons
                      voucher={ currentPromo }
                    />
                  }
                  descriptionType='buyNow'
                />
              }
            />)
          : null
      }
    </PromosContext.Provider>
  );
}

PromosController.propTypes = { slot: PropTypes.element };
