import React from 'react';
import './VendorCard.css';
import PropTypeShapes from '../../../services/Constants/PropTypeShapes.jsx';
import PropTypes from 'prop-types';
import { Vendor } from '../../../services/Constants/ObjectDefinitions.js';
import useVoucherProcessor from '../../../services/hooks/useVoucherProcessor.js';
import { captureMessage } from '@sentry/react';
import { AsyncImage } from 'loadable-image';
import { Grow } from 'transitions-kit';
import VodaPayClubBadgeIcon from '../../../assets/VodaPayClubBadgeIcon.jsx';

const getLogo = vendor => (vendor ? (<AsyncImage
  className='logo'
  loader={ <div className='empty skeleton-loader' /> }
  Transition={ Grow }
  onError={
    event => {
      captureMessage('Failed to load Logo image', 'warning');
      event.currentTarget.src = vendor?.iconUrl;
    }
  }
  src={ vendor.iconUrl }
  alt={ vendor.name }
/>)
  : <div className='logo' />);

const onLoad = element => {
  element.target.parentElement.style.background = '#000';
};

const getVenderImage = vendor => (
  <AsyncImage
    src={ vendor?.backgroundUrl }
    alt={ vendor?.name }
    loader={
      <div
        className='empty skeleton-loader'
      />
    }
    Transition={ Grow }
    className='vendor-img'
    onLoad={ onLoad }
    onError={
      event => {
        captureMessage('Failed to load Banner image', 'warning');
        event.currentTarget.style.backgroundImage = 'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 37.62%))';
        event.currentTarget.src = vendor?.backgroundUrl;
      }
    }
  />
);

/**
 * @param {object} props - the props of the component
 * @param {Vendor} props.vendor - the vendor to display
 * @param {boolean} props.hideInfo - whether to hide info or not
 * @param {object} props.category - the category of voucher
 * @returns {JSX.Element} VendorCard Component
 */
export default function VendorCard({ vendor, hideInfo, category }) {
  const { getBestValueVoucher, getBuyNowPercentValue } = useVoucherProcessor();
  const needExtra = vendor?.vouchers?.length > 0 && !hideInfo;

  const extra = needExtra && getBuyNowPercentValue(vendor.vouchers.reduce((accumulator, element) => getBestValueVoucher(accumulator, element)));
  const logo = getLogo(vendor);
  const vendorImg = getVenderImage(vendor);

  return (
    <figure
      className='vendor-card v-container-h'
    >
      {vendorImg}
      {logo}
      {
        extra !== 0 && extra
        && <p className='extra-info'>{`${Math.floor(extra)}% extra`}</p>
      }
      {
        !hideInfo && category?.isClubCategory ? (
          <figcaption className='vendor-name'>
            {vendor ? vendor.name : ''} <VodaPayClubBadgeIcon />
          </figcaption>
        ) : (
          <figcaption className='vendor-name'>
            {vendor ? vendor.name : ''}
          </figcaption>
        )
      }

    </figure>
  );
}

VendorCard.propTypes = {
  vendor: PropTypeShapes.vendor,
  hideInfo: PropTypes.bool,
  category: PropTypeShapes.category,
};
