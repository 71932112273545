import '../../components/Category/Card/CategoryCard.css';
import './VodaPayClubPage.css';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import VodaPayClubTicket from '../../assets/VodaPayClubTicket.jsx';
import ChevronRightIcon from '../../assets/ChevronRight.jsx';
import CategoryVendors from '../../components/Category/Vendors/CategoryVendors.jsx';
import CategoryVouchers from '../../components/Category/Vouchers/CategoryVouchers.jsx';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { SubscriptionDetailsContext } from '../../services/context/SubscriptionDetailsContext.js';

/**
 * @returns {JSX.Element} VodaPayClubPage component
 */
export default function VodaPayClubPage() {
  const analyticsName = `${pagePrefix}: manage subscription`;
  const navigateTo = useNavigate();
  const categories = useContext(CategoriesContext);
  const category = categories?.find(element => element.isClubCategory === true);
  const subscriptionDetails = useContext(SubscriptionDetailsContext);
  const subscriptionStatus = subscriptionDetails?.subscriptionStatus === 'unsubscribed' ? 'not active' : subscriptionDetails?.subscriptionStatus;

  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      event_name: [events.pageView],
      page_name: analyticsName,
      link_id: `${pagePrefix}: cancel subscription`,
      visitor_addon_type_active: `${defaultAnalyticsVariables.app_name}: subscription: ${subscriptionStatus}`,
    });
  }, []);

  const onClickAnalytics = () => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      event_name: [events.interaction],
      page_name: analyticsName,
      link_id: `${pagePrefix}: cancel subscription`,
      visitor_addon_type_active: `${defaultAnalyticsVariables.app_name}: subscription: ${subscriptionStatus}`,
    });
  };

  return (
    <div className='top-container'>
      <div className='image-container'>
        <VodaPayClubTicket />
      </div>
      <h2 className='title'>Your VodaPay Club Membership</h2>
      <h3 className='sub-title'> You&apos;re in and ready to get more value</h3>
      <button
        className='manage-button-style'
        onClick={
          () => {
            navigateTo(ROUTES.ARE_YOU_SURE);
            onClickAnalytics();
          }
        }
      >Cancel membership <ChevronRightIcon /></button>
      <CategoryVendors category={ category } />
      <CategoryVouchers category={ category } />
    </div>
  );
}
