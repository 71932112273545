import React, { useContext, useEffect } from 'react';
import './MaintenanceMessage.css';
import { ConfigContext } from '../../services/context/ConfigContext.js';
import { MaintenanceMessageContext } from '../../services/context/MaintenanceContext.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';

const analyticsName = `${pagePrefix}: maintenance`;

/**
 * @returns {JSX.Element} MaintenanceMessage Component
 */
export default function MaintenanceMessage() {
  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.pageView],
      link_id: `${pagePrefix}: error`,
      page_error_name: 'Error in loading data/page',
      page_error_code: 'N/A',
      page_error_type: 'system_error',
    });
  });
  const config = useContext(ConfigContext);
  const maintenanceMessage = useContext(MaintenanceMessageContext);
  return (
    <div className='maintenance-message v-container'>
      <h3>{'We\'re so sorry for the inconvenience'}</h3>
      <p>
        {maintenanceMessage ?? config?.frontend.general.maintenanceWarning.message}
      </p>
    </div>
  );
}
