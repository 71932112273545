import React, { useContext } from 'react';
import { withProfiler } from '@sentry/react';
import '../../components/Category/Card/CategoryCard.css';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { useNavigate } from 'react-router-dom';
import './ExtraValueTryNowBox.css';
import { SubscriptionDetailsContext } from '../../services/context/SubscriptionDetailsContext.js';

/**
 * @returns {JSX.Element} ExtraValueTryNowBox component
 */
function ExtraValueTryNowBox() {
  const navigateTo = useNavigate();
  const subscriptionDetails = useContext(SubscriptionDetailsContext);

  if (subscriptionDetails.isTrialAvailable) {
    return (
      <section>
        {
          <div className='access-container'>
            <div className='access-text'>
              <p className='sibling-text-header'>Enjoy extra value now</p>
              <p className='sibling-text-sub-header'>Join <span className='vodaPayClub-style'>VodaPay Club</span> for full access</p>
            </div>
            <div className='access-button'>
              <button
                className='button-style'
                onClick={ () => navigateTo(ROUTES.JOIN_VODAPAY_CLUB) }
              >Try for R0.00</button>
            </div>
          </div>
        }
      </section>
    );
  }

  return null;
}

export default withProfiler(ExtraValueTryNowBox);
