import React, { useContext, useEffect } from 'react';
import './CancellationPage.css';
import { withProfiler } from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import VoucherCancelIcon from '../../assets/VoucherCancelIcon.jsx';
import moment from 'moment';
import { SubscriptionDetailsContext, UpdateSubscriptionDetailsContext } from '../../services/context/SubscriptionDetailsContext.js';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';

/**
 * @returns {JSX.Element} CancellationPage component
 */
function CancellationPage() {
  const analyticsName = `${pagePrefix}: manage subscription: membership cancelled`;
  const subscriptionDetails = useContext(SubscriptionDetailsContext);
  const navigateTo = useNavigate();
  const expiryDate = moment(subscriptionDetails?.expiryDateAfterInactivation).format('DD-MM-YY');
  const subscriptionStatus = subscriptionDetails?.subscriptionStatus === 'unsubscribed' ? 'not active' : subscriptionDetails?.subscriptionStatus;
  const setSubscriptionDetails = useContext(UpdateSubscriptionDetailsContext);

  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      event_name: [events.pageView],
      page_name: analyticsName,
      visitor_addon_type_active: `${defaultAnalyticsVariables.app_name}: subscription: ${subscriptionStatus}`,
    });
  }, []);
  const onClickAnalytics = () => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      event_name: [events.interaction],
      page_name: analyticsName,
      link_id: `${pagePrefix}: back to vouchers`,
      visitor_addon_type_active: `${defaultAnalyticsVariables.app_name}: subscription: ${subscriptionStatus}`,
    });
  };

  return (
    <div className='voucherCancel-container'>
      <div className='voucherCancel-Icon'>
        <VoucherCancelIcon />
      </div>
      <div className='text-container'>
        <p className='text'>Membership cancelled</p>
        <p className='subText'>Your VodaPay Club membership is cancelled. Subscription ends by <span className='expiryDate-style'>{expiryDate}</span>. You can still use the vouchers you already bought.</p>
      </div>
      <div className='button-container'>
        <button
          className='back-button'
          onClick={
            () => {
              navigateTo(ROUTES.HOME);
              setSubscriptionDetails(null);
              onClickAnalytics();
            }
          }
        >Back to Vouchers</button>
      </div>
    </div>
  );
}

export default withProfiler(CancellationPage);
