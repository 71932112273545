import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useApis from '../services/hooks/useApis.js';
import { BearerTokenContext } from '../services/context/BearerTokenContext.js';
import { SubscriptionDetailsContext, UpdateSubscriptionDetailsContext } from '../services/context/SubscriptionDetailsContext.js';

/**
 * @param {object} props - The props for the controller
 * @param {React.ReactNode} props.slot - The content to display within this controller
 * @returns {JSX.Element} The Controller Component
 */
export default function SubscriptionDetailsController({ slot }) {
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const bearerToken = useContext(BearerTokenContext);
  const { getSubscriptionDetails } = useApis();

  // getSubscriptionDetails API integration
  const getSubscriptionPopUp = () => {
    getSubscriptionDetails(bearerToken)
      .then(response => {
        const expiryDate = Date.now();
        switch (response.status) {
          case 200: {
            response.isSubscribed = false;
            response.isTrialAvailable = response?.data?.isTrialAvailable;
            response.isShowPopUp = Boolean(response.isTrialAvailable);
            response.packageId = response?.data?.trialPackageId || response?.data?.packages[0]?.id;
            const subscriptionFeeObject = response?.data?.packages.filter(item => item?.product?.taxIncludedPrice !== '0.0');
            const subscriptionFee = subscriptionFeeObject[0]?.product?.taxIncludedPrice;
            const parsedValue = Number(subscriptionFee);
            const subscriptionFeeValue = !Number.isNaN(parsedValue)
              ? Number(subscriptionFee).toFixed(2) : 'Unable to fetch';
            response.subscriptionFee = subscriptionFeeValue;
            response.expiryDate = expiryDate;
            response.subscriptionStatus = 'unsubscribed';
            break;
          }

          case 409: {
            response.isSubscribed = true;
            response.isTrialAvailable = false;
            response.isShowPopUp = false;
            response.expiryDate = expiryDate;
            response.subscriptionId = response?.data?.subscriptionId;
            response.subscriptionStatus = response?.data?.SubscriptionStatus;
            response.expiryDateAfterInactivation = response?.data?.expirationDate;
            break;
          }

          case 423: {
            response.subscriptionStatus = 'restricted';
            response.isShowPopUp = false;
            response.expiryDate = expiryDate;
            break;
          }
        // No default
        }

        setSubscriptionDetails(response);
      })
      .catch(() => {
        setSubscriptionDetails(null);
      });
  };

  useEffect(() => {
    if (bearerToken) {
      getSubscriptionPopUp();
    }
  }, [bearerToken]);

  return (
    <SubscriptionDetailsContext.Provider value={ subscriptionDetails }>
      <UpdateSubscriptionDetailsContext.Provider value={ setSubscriptionDetails }>
        {slot}
      </UpdateSubscriptionDetailsContext.Provider>
    </SubscriptionDetailsContext.Provider>
  );
}

SubscriptionDetailsController.propTypes = { slot: PropTypes.element };
