import React, { useEffect, useState, useRef } from 'react';
import './Carousel.css';
import RadioButton from '../RadioButton/RadioButton.jsx';
import CloseIcon from '../../assets/CloseIcon.jsx';
import PropTypes from 'prop-types';
import useSliding from '../../services/hooks/useSliding.js';
import { captureMessage } from '@sentry/react';
import CarouselItem from '../CarouselItem/CarouselItem.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';

/**
 * @param {object} props - the props of the component
 * @param {Array<{ backgroundUrl: string, iconUrl: string, altText: string, actionItem: Element, headerText: string, subText: string }>} props.items - the items on the carousel
 * @param {Function} props.onClose - onClose handler
 * @returns {JSX.Element} Carousels Component
 */
export default function Carousel({ items, onClose }) {
  const [currentItem, setCurrentItem] = useState(0);
  const [animation, setAnimation] = useState(0);
  const [doTransition, setDoTransition] = useState(false);
  const analyticsName = `${pagePrefix}: carousel`;
  const carouselRef = useRef(null);
  const logoRef = useRef(null);

  /**
   * @param {1 | -1} direction to change display
   */
  const changeCarousel = direction => {
    if (items.length === 1) return;
    setDoTransition(true);
    setTimeout(() => {
      setAnimation(-direction * 100); // Move out to the direction of swipe (left for increase)
      setTimeout(() => {
        setDoTransition(false);
        setAnimation(direction * 100); // Snap to other side
        setTimeout(() => {
          setDoTransition(true);
          setAnimation(0); // Slide back in
          setCurrentItem(prevState => prevState + direction);
        }, 50);
      }, 50);
    }, 50);
  };

  const { distance, touching, onTouchEnd, onTouchStart, onTouchMove } = useSliding(distance_ => {
    const isLeft = distance_ < 0;
    const direction = isLeft ? 1 : -1;
    changeCarousel(direction);
  }, false);

  const translation = touching ? `${distance / 5}px` : `${animation}vw`;

  const { backgroundUrl, iconUrl, altText, actionItem, headerText, subText } = items.at(currentItem % items.length) ?? {};

  useEffect(() => {
    if (items.length > 0) {
      const interval = setInterval(() => {
        changeCarousel(1);
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    items,
    changeCarousel,
  ]);

  return (
    <section className='carousel v-container'>
      <div
        ref={ carouselRef }
        className='wrapper v-container'
        style={
          {
            backgroundImage: `url(${backgroundUrl})`,
            transform: `translateX(${translation})`,
            transition: doTransition ? 'transform .2s' : '',
          }
        }
        onTouchStart={ onTouchStart }
        onTouchMove={ onTouchMove }
        onTouchEnd={ onTouchEnd }
      >
        {
          (typeof onClose === 'function') ? (
            <button
              className='close'
              onClick={ () => onClose() }
            >
              <CloseIcon />
            </button>
          ) : null
        }

        <img
          ref={ logoRef }
          className='logo'
          onError={
            event => {
              captureMessage(`Failed to load image: ${event.currentTarget.src}`, 'warning');
              event.currentTarget.parentElement.style.backgroundImage = `url(${backgroundUrl})`;
              event.currentTarget.src = iconUrl;
              window.utag?.view({
                ...defaultAnalyticsVariables,
                page_name: analyticsName,
                event_name: [events.pageView],
                link_id: `${pagePrefix}: error`,
                event_error_name: 'carousel display error on landing page',
                event_error_code: 'N/A',
                event_error_type: 'system error',
              });
            }
          }
          src={ iconUrl }
          alt={ altText }
        />
        <CarouselItem
          headerText={ headerText }
          subText={ subText }
          actionItem={ actionItem }
        />
      </div>
      <ul className='container-h'>
        {
          items
            .map((category, index) => (
              <RadioButton
                key={ index }
                selected={ index === (items.length + (currentItem % items.length)) % items.length }
              />
            ))
        }
      </ul>
    </section>
  );
}

Carousel.propTypes = {
  items: PropTypes.array,
  onClose: PropTypes.func,
};
