import React, { useContext, useEffect, useState } from 'react';
import VouchersDisplay from '../../components/Vouchers/VouchersDisplay.jsx';
import Coupon from '../../components/Coupon/Coupon.jsx';
import ExpandableCouponBottom from '../../components/CouponBottoms/Expandable/ExpandableCouponBottom.jsx';
import { HistoryContext, UpdateHistoryContext } from '../../services/context/HistoryContext.js';
import MyVouchersHeader from '../../components/MyVouchersHeader/MyVouchersHeader.jsx';
import StubCouponBottom from '../../components/CouponBottoms/Stub/StubCouponBottom.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { ACTIVE_STATUSES } from '../../services/ActiveStatuses.js';
import { withProfiler } from '@sentry/react';
import EmptyMyVouchersIcon from '../../assets/EmptyMyVouchersIcon.jsx';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';
import CrossSellVoucher from '../../components/CrossSellVoucher/CrossSellVoucher.jsx';
import AddToCartButtons from '../../components/AddToCart/AddToCartButtons.jsx';
import { getRandomEntries } from '../../services/Random.js';
import EmptyIconButton from '../../components/EmptyIconButton/EmptyIconButton.jsx';
import { UpdateOverScrollHandlerContext } from '../../services/context/OverScrollContext.jsx';
import Loading from '../../components/Loading/Loading.jsx';
import { SubscriptionDetailsContext } from '../../services/context/SubscriptionDetailsContext.js';

/**
 * @returns {JSX.Element} MyVouchersPage component
 */
function MyVouchers() {
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState(null);
  const analyticsName = `${pagePrefix}: my vouchers`;
  const categories = useContext(CategoriesContext);
  const vendors = categories?.flatMap(category => category.vendors) ?? [];
  const allVouchers = vendors.flatMap(vendor => vendor.vouchers ?? []);
  const [randomVouchers, setRandomVouchers] = useState([]);
  const setHistory = useContext(UpdateHistoryContext);
  const setOverScrollHandler = useContext(UpdateOverScrollHandlerContext);
  const subscriptionDetails = useContext(SubscriptionDetailsContext);
  const subscriptionStatus = subscriptionDetails?.subscriptionStatus === 'unsubscribed' ? 'not active' : subscriptionDetails?.subscriptionStatus;

  useEffect(() => {
    setOverScrollHandler(() => () => setHistory(null));
    return () => setOverScrollHandler(null);
  }, []);

  useEffect(() => {
    if (categories) {
      setRandomVouchers(getRandomEntries(allVouchers, 2));
    }
  }, [categories]);

  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      page_name: analyticsName,
      event_name: [events.pageView],
    });
  }, []);

  const onClickAnalytics = () => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      event_name: [events.interaction],
      page_name: `${pagePrefix}: my vouchers`,
      link_id: `${pagePrefix}: view code`,
      visitor_addon_type_active: `${defaultAnalyticsVariables.app_name}: subscription: ${subscriptionStatus}`,
    });
  };

  const history = useContext(HistoryContext);
  const callToAction = 'Start Shopping';
  const filters = [
    {
      display: 'Active',
      isValid: gift => ACTIVE_STATUSES.includes(gift.status),
      onEmpty: (
        <>
          <EmptyIconButton
            icon={ <EmptyMyVouchersIcon /> }
            title={ 'Active vouchers will show up here.' }
            onClick={ () => navigateTo(ROUTES.HOME) }
            buttonText={ callToAction }
          />

          {
            randomVouchers.map(voucher => (
              <CrossSellVoucher
                key={ voucher.id }
                voucher={ voucher }
                actionItem={ <AddToCartButtons voucher={ voucher } /> }
              />
            ))
          }
        </>
      ),
    },
    {
      display: 'Expired',
      isValid: gift => gift.status === 'Expired',
      onEmpty: (
        <EmptyIconButton
          icon={ <EmptyMyVouchersIcon /> }
          title={ 'Expired vouchers will show up here.' }
          onClick={ () => navigateTo(ROUTES.HOME) }
          buttonText={ callToAction }
        />
      ),
    },
    {
      display: 'Redeemed',
      isValid: gift => gift.status === 'Redeemed',
      onEmpty: (
        <EmptyIconButton
          icon={ <EmptyMyVouchersIcon /> }
          title={ 'Redeemed vouchers will show up here.' }
          onClick={ () => navigateTo(ROUTES.HOME) }
          buttonText={ callToAction }
        />
      ),
    },
    {
      display: 'Advances',
      isValid: gift => gift.isAdvance,
      onEmpty: (
        <EmptyIconButton
          icon={ <EmptyMyVouchersIcon /> }
          title={ 'Advanced vouchers will show up here.' }
          onClick={ () => navigateTo(ROUTES.HOME) }
          buttonText={ callToAction }
        />
      ),
    },
  ];

  const [currentFilter, setCurrentFilter] = useState(0);

  const vouchers = history ? history.sort(gift => gift.purchaseDate)
    .filter(gift => gift.redemptionCode?.length > 1)
    .filter(gift => filters[currentFilter].isValid(gift))
    .map(gift => (
      <Coupon
        key={ gift.reservationId }
        gift={ gift }
        bottom={
          <ExpandableCouponBottom
            gift={ gift }
            setLoading={ setLoading }
          />
        }
      />
    ))
    : Array.from({ length: 5 }).map((_, index) => (
      <Coupon
        key={ index }
        bottom={ <StubCouponBottom /> }
      />
    ));

  return (
    <AnalyticsPageContext.Provider value={ analyticsName }>
      <VouchersDisplay
        title={
          <MyVouchersHeader
            filters={ filters }
            currentFilter={ currentFilter }
            setCurrentFilter={ setCurrentFilter }
          />
        }
        vouchers={ vouchers }
        onEmpty={ filters[currentFilter].onEmpty }
        onClick={ () => onClickAnalytics() }
      />
      {loading ? <Loading headerText={ loading } /> : null }
    </AnalyticsPageContext.Provider>
  );
}

export default withProfiler(MyVouchers);
