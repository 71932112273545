import React, { useContext, useEffect, useRef, useState } from 'react';
import './CategoryIcons.css';
import CategoryIcon from '../../Category/Icon/CategoryIcon.jsx';
import { useNavigate } from 'react-router-dom';
import { CategoriesContext } from '../../../services/context/CategoriesContext.js';
import ROUTES from '../../../services/Constants/GlobalRoutes.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../../services/context/AnalyticsPageContext.js';
import { UserDetailsContext } from '../../../services/context/UserDetailsContext.js';

/**
 * @returns {JSX.Element} CategoryIcons Component
 */
export default function CategoryIcons() {
  const categories = useContext(CategoriesContext);
  const navigateTo = useNavigate();

  const [scrollX, setScrollX] = useState(0);
  const [touched, setTouched] = useState(false);
  const scrollInterval = useRef(null);
  const scrollForwardTimeout = useRef(null);
  const scrollBackwardTimeout = useRef(null);
  const scrollStopTimeout = useRef(null);
  const listRef = useRef(null);
  const userDetails = useContext(UserDetailsContext);

  useEffect(() => {
    if (!touched && scrollX) {
      scrollInterval.current = setInterval(() => {
        listRef.current.scrollBy(scrollX, 0);
      }, 70);
    }

    return () => clearInterval(scrollInterval.current);
  }, [
    touched,
    scrollX,
  ]);

  useEffect(() => {
    if (!touched) {
      scrollForwardTimeout.current = setTimeout(() => {
        setScrollX(1);
        scrollBackwardTimeout.current = setTimeout(() => {
          setScrollX(-1);
          scrollStopTimeout.current = setTimeout(() => {
            setScrollX(0);
          }, 1000);
        }, 1000);
      }, 10_000);
    }

    return () => {
      clearTimeout(scrollForwardTimeout.current);
      clearTimeout(scrollBackwardTimeout.current);
      clearTimeout(scrollStopTimeout.current);
    };
  }, [
    touched,
    scrollStopTimeout.current,
  ]);

  const analyticsPage = useContext(AnalyticsPageContext);
  const onClick = category => {
    window.utag?.link({
      ...defaultAnalyticsVariables,
      page_name: analyticsPage,
      event_name: [events.interaction],
      link_id: `${pagePrefix}: shop by category: ${category.name}`,
    });
    navigateTo(ROUTES.CATEGORY.get(category.id));
  };

  const loading = categories.length === 0;

  return (
    <section className='category-icons v-container'>
      <header className={ `container-v${loading ? ' empty' : ''}` }>
        <h3 className={ `${loading ? 'skeleton-loader' : ''}` }>{loading ? '' : 'Shop by category'}</h3>
      </header>
      <ul
        className='container'
        ref={ listRef }
        onTouchStart={ () => setTouched(true) }
      >
        {
          categories.map(category => (
            userDetails.vsp === false && category.isClubCategory ? null : (
              <button
                key={ category.id }
                onClick={ () => onClick(category) }
              >
                <CategoryIcon category={ category } />
              </button>
            )
          ))
        }
        {
          loading && Array.from({ length: 10 }).map((_, index) => (
            <div
              className='container'
              key={ index }
            ><CategoryIcon /></div>
          ))
        }
      </ul>
    </section>
  );
}
