import React, { useContext, useEffect } from 'react';
import './FallenRocketErrorPage.css';
import FallenRocket from '../../assets/FallenRocket.jsx';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { SubscriptionDetailsContext } from '../../services/context/SubscriptionDetailsContext.js';

/**
 * @returns {JSX.Element} FallenRocketErrorPage
 */
export default function FallenRocketErrorPage() {
  const navigateTo = useNavigate();
  const subscriptionDetails = useContext(SubscriptionDetailsContext);
  const subscriptionStatus = subscriptionDetails?.subscriptionStatus === 'unsubscribed' ? 'not active' : subscriptionDetails?.subscriptionStatus;

  useEffect(() => {
    window.utag?.view({
      ...defaultAnalyticsVariables,
      event_name: [events.pageView],
      page_name: `${pagePrefix}: summary`,
      page_error_name: 'Error occured while processing your payment',
      visitor_addon_type_active: `${defaultAnalyticsVariables.app_name}: subscription: ${subscriptionStatus}`,
    });
  }, []);
  return (
    <div className='fallen-rocket-container'>
      <div className='fallen-rocket-icon'>
        <FallenRocket />
      </div>
      <div className='text-container'>
        <p className='text'>Oh no! <br />Something went wrong</p>
        <p className='sub-text'>An error occurred while processing your payment. Please try again.</p>
      </div>
      <div className='button-container'>
        <button
          className='main-button'
          onClick={ () => navigateTo(ROUTES.HOME) }
        >
          Back to home
        </button>
      </div>
    </div>
  );
}
